const dashboardRoutes = (prop) => [
  {
      path: 'dashboard',
      name: prop + '.dashboard',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/Dashboard.vue')
  }
]
const configurationRoutes = (prop) => [
  {
    path: 'country-info',
    name: prop + '.country_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/country-info/List.vue')
  },
  {
    path: 'application-type',
    name: prop + '.application_type',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application-type/List.vue')
  },
  {
    path: 'applicant-type',
    name: prop + '.applicant_type',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/applicant-type/List.vue')
  },
  {
    path: 'capital-info',
    name: prop + '.capital_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/capital-info/List.vue')
  },
  {
    path: 'port-info',
    name: prop + '.port_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/port-info/List.vue')
  },
  {
    path: 'currency',
    name: prop + '.currency',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/currency/List.vue')
  },
  {
    path: 'product',
    name: prop + '.product',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/product/List.vue')
  },
  {
    path: 'unit-measurement',
    name: prop + '.unit_measurement',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/unit-measurement/List.vue')
  },
  {
    path: 'section',
    name: prop + '.section',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/section/List.vue')
  },
  {
    path: 'chapter',
    name: prop + '.chapter',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/chapter/List.vue')
  },
  {
    path: 'heading',
    name: prop + '.heading',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/heading/List.vue')
  },
  {
    path: 'sub-heading',
    name: prop + '.sub_heading',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/sub-heading/List.vue')
  },
  {
    path: 'subject-info',
    name: prop + '.subject_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/subject-info/List.vue')
  },
  {
    path: 'region',
    name: prop + '.region',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/region/List.vue')
  },
  {
    path: 'comments',
    name: prop + '.comments',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/comment/List.vue')
  },
  {
    path: 'ratings',
    name: prop + '.ratings',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/rating/List.vue')
  },
  {
    path: 'report-heading',
    name: prop + '.report_heading',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report-heading/List.vue')
  }
]

const routes = [
  {
    path: '/trade-tariff-service',
    name: 'trade_tariff_service',
    component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
    meta: { auth: false },
    children: dashboardRoutes('trade_tariff_service')
  },
  {
    path: '/trade-tariff-service/configuration',
    name: 'trade_tariff_service.configuration',
    component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
    meta: { auth: true },
    children: configurationRoutes('trade_tariff_service.configuration')
  }
]

export default routes
