const btriRoutes = (prop) => [
  {
    path: 'test-services',
    name: prop + '.test_services',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/List.vue')
  },
  {
    path: 'test-service-form',
    name: prop + '.test_service_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/Form.vue')
  },
  {
    path: 'test-service-form/:id',
    name: prop + '.test_service_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/Form.vue')
  },
  {
    path: 'test-service/:id',
    name: prop + '.test_services_view',
    meta: { auth: true },
    props: true,
    component: () => import('../pages/common-services/Details.vue')
  },
  {
    path: 'test-service-received-list',
    name: prop + '.test_service_received_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/ReceivedList.vue')
  },
  // payments routes
  {
    path: 'payment/:id',
    name: prop + '.payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/Payment.vue')
  },
  {
    path: 'test/payment/success',
    name: prop + '.payment_success',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/PaymentSuccess.vue')
  },
  {
    path: 'test/payment/cancel',
    name: prop + '.payment_cancel',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/PaymentCancel.vue')
  },
  {
    path: 'test/payment/decline',
    name: prop + '.payment_decline',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/PaymentDecline.vue')
  },
  {
    path: 'test-service-forwarded-list',
    name: prop + '.test_service_forwarded_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/ForwardedList.vue')
  },
  {
    path: 'test-service-report-prepare-list',
    name: prop + '.test_service_report_prepare_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/ReportPrepareList.vue')
  },
  {
    path: 'test-service-report-publish-list',
    name: prop + '.test_service_report_publish_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/ReportPublishList.vue')
  },
  // for view pages custom design
  {
    path: 'view-1',
    name: prop + '.view1',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/Details/file1.vue')
  },
  {
    path: 'view-2',
    name: prop + '.view2',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/Details/file2.vue')
  },
  {
    path: 'view-3',
    name: prop + '.view3',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/common-services/Details/file3.vue')
  },

  {
    path: 'clone-price-info',
    name: prop + '.clone_price_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/clone-biclone-management/clone-price-info/List.vue')
  },
  {
    path: 'clone-price-info-add',
    name: prop + '.clone_price_info_add',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/clone-biclone-management/clone-price-info/Form.vue')
  },
  {
    path: 'clone-report',
    name: prop + '.clone_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/clone-biclone-management/clone-report/List.vue')
  },

  // btri-service routes start...
  {
    path: 'service-app-entry',
    name: prop + '.service_app_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-supply-application/applicationForm.vue')
  },
  {
    path: 'service-app-approval',
    name: prop + '.service_app_approval',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-supply-application/approvalForm.vue')
  },
  {
    path: 'service-app-list',
    name: prop + '.service_app_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-supply-application/List.vue')
  },
  {
    path: 'service-app-details',
    name: prop + '.service_app_details',
    meta: { auth: true },
    props: true,
    component: () => import('../pages/btri-service/tea-plant-supply-application/Details.vue')
  },
  {
    path: 'tea-plant-payment/:id',
    name: prop + '.tea_plant_payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-supply-application/Payment.vue')
  },
  {
    path: 'tea-plant-payment-success',
    name: prop + '.tea_plant_payment_success',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-supply-application/PaymentSuccess.vue')
  },
  {
    path: 'tea-plant-payment-cancel',
    name: prop + '.tea_plant_payment_cancel',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-supply-application/PaymentCancel.vue')
  },
  {
    path: 'tea-plant-payment-decline',
    name: prop + '.tea_plant_payment_decline',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-supply-application/PaymentDecline.vue')
  },
  {
    path: 'planting-material',
    name: prop + '.planting_material',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/planting-material/List.vue')
  },
  {
    path: 'master-purpose',
    name: prop + '.master_purpose',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/master-purpose/List.vue')
  },
  {
    path: 'master-purpose-form',
    name: prop + '.master_purpose_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/master-purpose/Form.vue')
  },
  {
    path: 'tea-plant-stock-in',
    name: prop + '.tea_plant_stock_in',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-stock-in/List.vue')
  },
  {
    path: 'tea-production-stock-in-form',
    name: prop + '.tea_production_stock_in_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-stock-in/Form.vue')
  },
  {
    path: 'tea-plant-stock-out',
    name: prop + '.tea_plant_stock_out',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-stock-out/List.vue')
  },
  {
    path: 'tea-plant-supply-stock-out-form',
    name: prop + '.tea_plant_supply_stock_out_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/tea-plant-stock-out/Form.vue')
  },
  {
    path: 'tea-plant-current-stock-report',
    name: prop + '.tea_plant_current_stock_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/reports/current-stock-report.vue')
  },
  {
    path: 'prepare-schedule',
    name: prop + '.prepare_schedule',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/prepare-schedule/List.vue')
  },
  {
    path: 'add-prepare-schedule',
    name: prop + '.add_prepare_schedule',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/prepare-schedule/Form.vue')
  },
  {
    path: 'edit-prepare-schedule/:id',
    name: prop + '.edit_prepare_schedule',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/prepare-schedule/Form.vue')
  },
  // advisory vist route start
  {
    path: 'advisory-visit-schedule-list',
    name: prop + '.advisory_visit_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/List.vue')
  },
  {
    path: 'visit-application-list',
    name: prop + '.visit_schedule_app_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/VisitScheduleList.vue')
  },
  {
    path: 'visit-application-list/:id',
    name: prop + '.visit_application_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/VisitApplicationList.vue')
  },
  {
    path: 'advisory-visit-schedule',
    name: prop + '.advisory_visit_create',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/Form.vue')
  },
  {
    path: 'advisory-visit-schedule/:id',
    name: prop + '.advisory_visit_edit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/Form.vue')
  },
  {
    path: 'advisory-visit-schedule-view/:id',
    name: prop + '.advisory_visit_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/Details.vue')
  },
  {
    path: 'online-visit-application-list',
    name: prop + '.online_visit_schedule_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/OnlineAdvVisitAppList.vue')
  },
  {
    path: 'online-visit-garden-confirm-list',
    name: prop + '.visit_schedule_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/OnlineAdvVisitGardenConfirmList.vue')
  },
  {
    path: 'approve-visit-report',
    name: prop + '.approve_visit_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/btri-service/advisory-visit/ApproveVisitReport.vue')
  },
  // advisory route end
  // btri-service routes end...
    // reports
  {
    path: 'certificate-issue',
    name: prop + '.certificate_issue',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/certificate-issue/List.vue')
  },

    {
    path: 'certificate-issue-entry',
    name: prop + '.certificate_issue_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/certificate-issue/Form.vue')
  },

  // training-workshop routes start...
  {
    path: 'nomination-entry',
    name: prop + '.nomination_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/nomination/Form.vue')
  },
  {
    path: 'nomination-list',
    name: prop + '.nomination_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/nomination/List.vue')
  },

  {
    path: 'participate-entry',
    name: prop + '.participate_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/participation/Form.vue')
  },
  {
    path: 'participate-list',
    name: prop + '.participate_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/participation/List.vue')
  },
  {
    path: 'participate-report',
    name: prop + '.participate_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/reports/participate-report.vue')
  },
  {
    path: 'training-report',
    name: prop + '.training_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/training-workshop/reports/training-report.vue')
  }

]

const routes = [
  {
    path: '/tea-garden-service/btri',
    name: 'tea_garden_service.btri',
    component: () => import('@/layouts/TeaGardenServiceLayout.vue'),
    meta: { auth: true },
    children: btriRoutes('tea_garden_service.btri')
  }
]

export default routes
