export default {
    mutateCommonProperties ({ commit }, payload) {
      commit('mutateCommonProperties', payload)
    },
    isToggleNotification ({ commit }, payload) {
      commit('isToggleNotification', payload)
    },
    setNotificationList ({ commit }, payload) {
      commit('setNotificationList', payload)
    },
    setPushNotification ({ commit }, payload) {
      commit('setPushNotification', payload)
    },
    removeSinglePushNotification ({ commit }, payload) {
      commit('removeSinglePushNotification', payload)
    },
    setTotalNotification ({ commit }, payload) {
      commit('setTotalNotification', payload)
    },
    removeItemFromSetList ({ commit }, payload) {
      commit('removeItemFromSetList', payload)
    },
    setList ({ commit }, payload) {
      commit('setList', payload)
    },
    resetList ({ commit }) {
      commit('resetList', [])
    },
    toggleStatus ({ commit }, payload) {
      commit('toggleStatus', payload)
    },
    toggleDropdownItemStatus ({ commit }, payload) {
      commit('toggleDropdownItemStatus', payload)
    },
    setCurrentFiscalYearId ({ commit }, payload) {
      commit('setCurrentFiscalYearId', payload)
    }
  }
