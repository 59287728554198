const dashboardRoutes = (prop) => [
  {
      path: 'dashboard',
      name: prop + '.dashboard',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/Dashboard.vue')
  }
]
const worldTradeDirectoryManagementRoutes = (prop) => [
  {
    path: 'country-information-management',
    name: prop + '.country_information_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/country-information-management/List.vue')
  },
  {
    path: 'tariff-profile-management',
    name: prop + '.tariff_profile_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tariff-profile-management/List.vue')
  },
  {
    path: 'wto-rto-info-management',
    name: prop + '.wto_rto_info_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/wto-rto-info-management/List.vue')
  },
  {
    path: 'bilateral-trade-management',
    name: prop + '.bilateral_trade_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/bilateral-trade-management/List.vue')
  },
  {
    path: 'export-management',
    name: prop + '.export_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/export-management/List.vue')
  },
  {
    path: 'import-management',
    name: prop + '.import_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/import-management/List.vue')
  }
]

const routes = [
  {
    path: '/trade-tariff-service',
    name: 'trade_tariff_service',
    component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
    meta: { auth: false },
    children: dashboardRoutes('trade_tariff_service')
  },
  {
    path: '/trade-tariff-service/world-trade-directory-management',
    name: 'trade_tariff_service.world_trade_directory_management',
    component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
    meta: { auth: true },
    children: worldTradeDirectoryManagementRoutes('trade_tariff_service.world_trade_directory_management')
  }
]

export default routes
