const tradeFairManageRoutes = (prop) => [
    {
      path: 'receive-application',
      name: prop + '.receive_application',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/app-manage/ReceiveList.vue')
    },
    {
      path: 'approve-application',
      name: prop + '.approve_application',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/app-manage/ApproveList.vue')
    },
    {
      path: 'assing-stall',
      name: prop + '.assing_stall',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/stall-manage/AssignStallCategoryList.vue')
    },
    {
      path: 'return-refund-data-list',
      name: prop + '.return_refund_data_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/return-refund/List.vue')
    },
    {
      path: 'return-refund-list',
      name: prop + '.return_refund_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/return-refund/ReturnRefundList.vue')
    },
    {
      path: 'stall-booked-list',
      name: prop + '.stall_booked_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/stall-booked-list/List.vue')
    },
    {
      path: 'category-wise-stall-list',
      name: prop + '.category_wise_stall_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/stall-manage/CategoryWiseStallList.vue')
    }
  ]
  const routes = [
    {
      path: '/trade-fair-service/ditf/trade-fair-management',
      name: 'trade_fair_service.trade_fair_management',
      component: () => import('@/layouts/TradeFairServiceLayout.vue'),
      meta: { auth: true },
      children: tradeFairManageRoutes('trade_fair_service.trade_fair_management')
    }
  ]
  export default routes
