import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const state = {
  commonObj: {
    hasDropdownLoaded: false,
    divisionList: [],
    districtList: [],
    fiscalYearList: []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
