const childRoutes = (prop) => [
  {
    path: 'training-calendar',
    name: prop + 'training_calendar',
    meta: { auth: true },
    component: () => import('../pages/training-calendar/List.vue')
  },
  {
    path: 'training-calendar-form',
    name: prop + 'training_calendar_form',
    meta: { auth: true },
    component: () => import('../pages/training-calendar/Form.vue')
  },
  {
    path: 'trainer-document',
    name: prop + 'trainer_document',
    meta: { auth: true },
    component: () => import('../pages/trainer-profile/List.vue')
  },
  {
    path: 'course-managment',
    name: prop + 'course_managment',
    meta: { auth: true },
    component: () => import('../pages/course-managment/List.vue')
  },
  {
    path: 'course-managment-form',
    name: prop + 'course_managment_form',
    meta: { auth: true },
    component: () => import('../pages/course-managment/Form.vue')
  },
  {
    path: 'course-module',
    name: prop + 'course_module',
    meta: { auth: true },
    component: () => import('../pages/course-module/List.vue')
  },
  {
    path: 'course-module-form',
    name: prop + 'course_module_form',
    meta: { auth: true },
    component: () => import('../pages/course-module/Form.vue')
  },
  {
    path: 'training-schedule',
    name: prop + 'training_schedule',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/List.vue')
  },
  {
    path: 'training-schedule-request',
    name: prop + 'training_schedule_request',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/RequestList.vue')
  },
  {
    path: 'routine',
    name: prop + 'routine',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/routine.vue')
  },
  {
    path: 'training-schedule-form',
    name: prop + 'training_schedule-form',
    meta: { auth: true },
    component: () => import('../pages/training-schedule/Form.vue')
  },
  {
    path: 'trainer-sorting',
    name: prop + 'trainer_sorting',
    meta: { auth: true },
    component: () => import('../pages/trainer-sorting/List.vue')
  },
  {
    path: 'trainer-sorting-form',
    name: prop + 'trainer_sorting_form',
    meta: { auth: true },
    component: () => import('../pages/trainer-sorting/Form.vue')
  },
  {
    path: 'trainer-selection',
    name: prop + 'trainer_selection',
    meta: { auth: true },
    component: () => import('../pages/trainer-selection/List.vue')
  },
  {
    path: 'trainer-selection-form',
    name: prop + 'trainer_selection',
    meta: { auth: true },
    component: () => import('../pages/trainer-selection/Form.vue')
  },
  {
    path: 'training-material-info',
    name: prop + '.training_material_info',
    meta: { auth: true },
    component: () => import('../pages/training-material-info/List.vue')
  },
  {
    path: 'training-material-form',
    name: prop + '.training_material_form',
    meta: { auth: true },
    component: () => import('../pages/training-material-info/Form.vue')
  },
  {
    path: 'training-material-view',
    name: prop + '.training_material_view',
    meta: { auth: true },
    component: () => import('../pages/training-material-info/Details.vue')
  },
  {
    path: 'training-attendance-sheet',
    name: prop + 'training_attendance_sheet',
    meta: { auth: true },
    component: () => import('../pages/training-attendance-sheet/Attendance.vue')
  },
  {
    path: 'attendance-upload',
    name: prop + 'attendance_upload',
    meta: { auth: true },
    component: () => import('../pages/attendance-upload/List.vue')
  }
]

const routes = [
    {
      path: '/training-e-learning-service/tpm',
      name: 'training_e_learning_service.tpm',
      component: () => import('@/layouts/TrainingServiceLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_learning_service.tpm')
    }
]

export default routes
