
const setRoutes = (prop) => [
  {
    path: 'license-application',
    name: prop + '.license_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-license-application/AdminList.vue')
  },
  {
    path: 'formalin-license-form',
    name: prop + '.formalin_license_from',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-license-application/AdminForm.vue')
  },
  {
    path: 'license-form-edit/:id',
    name: prop + '.license_from_edit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-license-application/AdminForm.vue')
  },
  {
    path: 'clearance-application',
    name: prop + '.clearance_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-clearance-application/AdminList.vue')
  },
  {
    path: 'formalin-clearance-form',
    name: prop + '.formalin_clearance_from',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-clearance-application/AdminForm.vue')
  },
  {
    path: 'clearance-form-edit/:id',
    name: prop + '.clearance_from_edit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-clearance-application/AdminForm.vue')
  },
  {
    path: 'license-application',
    name: prop + '.license_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-license-application/AdminList.vue')
  },
  {
    path: 'application-certificate/:id',
    name: prop + '.application_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-license-application/Certificate.vue')
  }
]
const setLicenseRoutes = (prop) => [
  {
    path: 'application-list',
    name: prop + '.application_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-license/application-list/List.vue')
  },
  {
    path: 'verification-list',
    name: prop + '.verification_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-license/verification/List.vue')
  },
  {
    path: 'inspection-list',
    name: prop + '.inspection_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-license/inspection/List.vue')
  },
  {
    path: 'approval-list',
    name: prop + '.approval_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-license/approval/List.vue')
  },
  {
    path: 'approved-list',
    name: prop + '.approved_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-license/approved/List.vue')
  },
  {
    path: 'rejected-list',
    name: prop + '.rejected_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-license/rejected/List.vue')
  },
  {
    path: 'details/:id',
    name: prop + '.details',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-license-application/Details.vue')
  },
  {
    path: 'forward',
    name: prop + '.forward',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-license/components/Forward.vue')
  }
]
const setClearanceRoutes = (prop) => [
  {
    path: 'application-list',
    name: prop + '.application_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-clearance/application-list/List.vue')
  },
  {
    path: 'verification-list',
    name: prop + '.verification_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-clearance/verification/List.vue')
  },
  {
    path: 'inspection-list',
    name: prop + '.inspection_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-clearance/inspection/List.vue')
  },
  {
    path: 'approval-list',
    name: prop + '.approval_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-clearance/approval/List.vue')
  },
  {
    path: 'approved-list',
    name: prop + '.approved_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-clearance/approved/List.vue')
  },
  {
    path: 'rejected-list',
    name: prop + '.rejected_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-clearance/rejected/List.vue')
  },
  {
    path: 'details/:id',
    name: prop + '.details',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/formalin-clearance-application/Details.vue')
  },
  {
    path: 'forward',
    name: prop + '.forward',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/formalin-clearance/components/Forward.vue')
  }
]

const routes = [
  {
    path: '/lrcpn-service/formalin-license',
    name: 'lrcpn_service.formalin_license',
    component: () => import('@/layouts/LrcpnServiceLayout.vue'),
    meta: { auth: true },
    children: setLicenseRoutes('lrcpn_service.formalin_license')
  },
  {
    path: '/lrcpn-service/formalin-clearance',
    name: 'lrcpn_service.formalin_clearance',
    component: () => import('@/layouts/LrcpnServiceLayout.vue'),
    meta: { auth: true },
    children: setClearanceRoutes('lrcpn_service.formalin_clearance')
  },
  {
    path: '/lrcpn-service/formalin-import-management',
    name: 'lrcpn_service.formalin_import_management',
    component: () => import('@/layouts/LrcpnServiceLayout.vue'),
    meta: { auth: true },
    children: setRoutes('lrcpn_service.formalin_import_management')
  }
]

export default routes
