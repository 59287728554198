const btbRoutes = (prop) => [
  {
    path: 'task',
    name: prop + '.task',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/task/List.vue')
  },
  {
    path: 'task-assign',
    name: prop + '.task_assign',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/task-assign/List.vue')
  },
  {
    path: 'task-assign-form',
    name: prop + '.task_assign_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/task-assign/Form.vue')
  },
  {
    path: 'task-assign-view/:id',
    name: prop + '.task_assign_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/task-assign/Details.vue')
  },
  {
    path: 'task-assign-achievement/:id',
    name: prop + '.task_assign_achievement',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/task-assign/Achievement.vue')
  },
  {
    path: 'task-assign-achievement-individual/:id',
    name: prop + '.task_assign_achievement_individual',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/task-assign/AchievementIndividual.vue')
  },
  {
    path: 'task-assign-achievement-individual-list/:id',
    name: prop + '.task_assign_achievement_individual_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/task-assign/AchievementIndividualList.vue')
  },
  {
    path: 'task-assign-achievement-individual-details/:id',
    name: prop + '.task_assign_achievement_individual_details',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/task-assign/AchievementIndividualDetails.vue')
  },
  {
    path: 'wages-head',
    name: prop + '.wages_head',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/wages-head/List.vue')
  },
  {
    path: 'advance-salary',
    name: prop + '.advance_salary',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/advance-salary/List.vue')
  },
  {
    path: 'provident-fund',
    name: prop + '.provident_fund',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/provident-fund/List.vue')
  },
  {
    path: 'plucking-ot-amount',
    name: prop + '.plucking_ot_amount',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/plucking-ot-amount/List.vue')
  },
  {
    path: 'create-salary',
    name: prop + '.create_salary',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/create-salary/List.vue')
  },
   // managment monthly salary
  {
    path: 'salary-sheet-management',
    name: prop + '.salary_sheet_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-management/List.vue')
  },
  {
    path: 'salary-sheet-management-report',
    name: prop + '.salary_sheet_management_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-management/Form.vue')
  },

  // staff monthly salary
  {
    path: 'salary-sheet-monthly-staff',
    name: prop + '.salary_sheet_monthly_staff',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-staff-monthly/List.vue')
  },
  {
    path: 'salary-sheet-monthly-staff-report',
    name: prop + '.salary_sheet_management_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-staff-monthly/View.vue')
  },
 // Weekly Laborer weekly salary
  {
    path: 'salary-sheet-staff',
    name: prop + '.salary_sheet_staff',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-staff/List.vue')
  },
  {
    path: 'staff-salary-view',
    name: prop + '.staff_salary_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-staff/View.vue')
  },
 // Monthly Laborer weekly salary
  {
    path: 'salary-sheet-monthly-laborer',
    name: prop + '.salary_sheet_monthly_laborer',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-warker-monthly/List.vue')
  },
  {
    path: 'salary-sheet-monthly-laborer-view',
    name: prop + '.salary_sheet_monthly_laborer_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/salaryManagement/salary-sheet-warker-monthly/View.vue')
  },
  //
  {
    path: 'ex-garden-online-application',
    name: prop + '.ex_garden_online_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/exGarden/online-application/List.vue')
  },
  {
    path: 'circulars',
    name: prop + '.circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request/circular/List.vue')
  },
  // fertilizer request
  {
    path: 'indent-submission',
    name: prop + '.indent_submission',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request/online-application/Form.vue')
  },
  {
    path: 'indent-submission/:id',
    name: prop + '.indent_submission_edit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request/online-application/Form.vue')
  },
  {
    path: 'indent-submission-list',
    name: prop + '.indent_submissions',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request/online-application/List.vue')
  },
  {
    path: 'indent-final-allocation-list',
    name: prop + '.indent_final_allocations',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request/online-application/FinalAllocationList.vue')
  },
  {
    path: 'indent-submissions-view/:id',
    name: prop + '.indent_submission_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request/online-application/Details.vue')
  },
  {
    path: 'return-one',
    name: prop + '.return_one',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-one/List.vue')
  },
  {
    path: 'return-one-entry',
    name: prop + '.return_one_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-one/Form.vue')
  },
  {
    path: 'return-two-entry',
    name: prop + '.return_two_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-two/Form.vue')
  },
  {
    path: 'return-three',
    name: prop + '.return_three',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-three/List.vue')
  },
  {
    path: 'return-form-three',
    name: prop + '.return_form_three',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-three/Form.vue')
  },
  // {
  //   path: 'return-form-three-view/:id',
  //   name: prop + '.return_form_three_view',
  //   meta: { auth: true, name: 'Editable' },
  //   component: () => import('../pages/return-three/Details.vue')
  // },
  {
    path: 'return-form-2',
    name: prop + '.return_form_2',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-two/List.vue')
  },
  {
    path: 'tea-production-stock-in',
    name: prop + '.task_assign',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-production-stock-in/List.vue')
  },
  {
    path: 'tea-production-stock-in-form',
    name: prop + '.task_assign_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-production-stock-in/Form.vue')
  },
  {
    path: 'tea-transfer-broker',
    name: prop + '.task_assign',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-transfer-broker/List.vue')
  },
  {
    path: 'tea-transfer-broker-form',
    name: prop + '.task_assign_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-transfer-broker/Form.vue')
  },
  {
    path: 'garden-stock-in',
    name: prop + '.garden_stock_in',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/green-leaf-stock-management/garden-stock-in/List.vue')
  },
  {
    path: 'garden-stock-out',
    name: prop + '.garden_stock_out',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/green-leaf-stock-management/garden-stock-out/List.vue')
  },
  {
    path: 'garden-stock-in-out',
    name: prop + '.garden_stock_in_out',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/green-leaf-stock-management/garden-stock-in-out/List.vue')
  },
  {
    path: 'garden-stock-in-out-report',
    name: prop + '.garden_stock_in_out_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/green-leaf-stock-management/garden-stock-in-out/StockInOutReport.vue')
  },
  {
    path: 'green-leaf-transfer-factory',
    name: prop + '.green_leaf_transfer_factory',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/green-leaf-stock-management/green-leaf-transfer-factory/List.vue')
  },
  {
    path: 'green-leaf-receive-from-garden',
    name: prop + '.green_leaf_receive_from_garden',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/green-leaf-stock-management/green-leaf-receive-from-garden/List.vue')
  },
  // online advisory visit
  {
    path: 'advisory-visit-list',
    name: prop + '.advisory_visit_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/advisory-visit/List.vue')
  },
  // tea Sell
  {
    path: 'tea-sale',
    name: prop + '.tea_sale',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-sale/List.vue')
  },
  {
    path: 'tea-sale-entry',
    name: prop + '.tea_sale_enry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-sale/Form.vue')
  }
  // {
  //   path: 'plucking-ot-amount',
  //   name: prop + '.plucking_ot_amount',
  //   meta: { auth: true, name: 'Editable' },
  //   component: () => import('../pages/configuration/plucking-ot-amount/List.vue')
  // }
]

const routes = [
  {
    path: '/tea-garden-service/garden',
    name: 'tea_garden_service.garden',
    component: () => import('@/layouts/TeaGardenServiceLayout.vue'),
    meta: { auth: true },
    children: btbRoutes('tea_garden_service.garden')
  }
]

export default routes
