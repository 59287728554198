  const HsCodeManagementRoutes = (prop) => [
    {
      path: 'hs-code-profile',
      name: prop + '.hs_code_profile',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/hs-code-profile/List.vue')
    },
    {
      path: 'search-hs-code',
      name: prop + '.search_hs_code',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/search-hs-code/List.vue')
    }
  ]

  const routes = [
    {
      path: '/trade-tariff-service/hs-code-management',
      name: 'trade_tariff_service.hs_code_management',
      component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
      meta: { auth: true },
      children: HsCodeManagementRoutes('trade_tariff_service.hs_code_management')
    }
  ]

export default routes
