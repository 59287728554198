const btbRoutes = (prop) => [
  {
    path: 'tree-disposal',
    name: prop + '.tree-disposal',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/TreeDisposalDashboard.vue')
  },
  {
    path: 'btb-garden-service',
    name: prop + 'btb-three',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/BtbGardenServiceDashboard.vue')
  },
  {
    path: 'small-tea-farmer',
    name: prop + '.small-tea-farmer',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/SmallTeaFarmerDashboard.vue')
  },
  {
    path: 'tea-factory-dashboard',
    name: prop + 'tea-factory-dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/TeaFactoryDashboard.vue')
  },
  {
    path: 'btb-six',
    name: prop + 'btb-six',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/BtbSix.vue')
  },
  {
    path: 'clone-bi-clone',
    name: prop + 'clone_bi_clone',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/CloneBiCloneDashboard.vue')
  },
  {
    path: 'tea-plant-supply',
    name: prop + 'tea_plant_supply',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/TeaPlantSupply.vue')
  },
  {
    path: 'btri-test-services',
    name: prop + '.btri-test-services',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/BtriTestDashboard.vue')
  },
  {
    path: 'training-workshop',
    name: prop + 'training_workshop',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/TrainingWorkshop.vue')
  },
  {
    path: 'advisory-visit',
    name: prop + '.advisory_visit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/AdvisoryVisitBtri.vue')
  },
  {
    path: 'education-trust-scholarship',
    name: prop + '.education-trust-scholarship',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/pdu/EducationTrustScholarship.vue')
  },
  {
    path: 'professional-course-management',
    name: prop + '.professional_course_management',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/pdu/ProfessionalCourseManagement.vue')
  },
  {
    path: 'regular-garden-inspection',
    name: prop + 'regular-garden-inspection',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/pdu/RegularGardenInspection.vue')
  },
  {
    path: 'fertilizer-distribution',
    name: prop + 'fertilizer_distribution',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/FertilizerDistribution.vue')
  },
  {
    path: 'online-tea-license-dashboard',
    name: prop + '.online_tea_license_dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/OnlineTeaLicenseDashboard.vue')
  },
  {
    path: 'tea-production-dashboard',
    name: prop + '.tea_production_dashboard',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/dashboard/TeaProductionDashboard.vue')
  }
]

const routes = [
  {
    path: '/tea-garden-service/dashboard',
    name: 'tea_garden_service.dashboard',
    component: () => import('@/layouts/TeaGardenServiceLayout.vue'),
    meta: { auth: true },
    children: btbRoutes('tea_garden_service.dashboard')
  }
]

export default routes
