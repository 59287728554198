const paymentType = [
    {
        value: 'Trainer',
        text_en: 'Trainer',
        text_bn: 'প্রশিক্ষক'
    },
    {
        value: 'Trainee',
        text_en: 'Trainee',
        text_bn: 'প্রশিক্ষণার্থী'
    }
]
const committeeType = [
    {
        value: 1,
        text_en: 'Course Management Team',
        text_bn: 'কোর্স ম্যানেজমেন্ট টিম'
    },
    {
        value: 2,
        text_en: 'Executive Management Team',
        text_bn: 'এক্সিকিউটিভ ম্যানেজমেন্ট টিম'
    }
]
const sheduleType = [
    {
        value: 'Other',
        text_en: 'Other',
        text_bn: 'অন্যান্য'
    },
    {
        value: 'Trainer',
        text_en: 'Trainer',
        text_bn: 'প্রশিক্ষক'
    }
]
const jobTypeList = [
    {
        value: '1',
        text_en: 'Cader',
        text_bn: 'ক্যাডার'
    },
    {
        value: '2',
        text_en: 'Non-Cader',
        text_bn: 'নন-ক্যাডার'
    }
]
const userPriceTypeList = [
    {
        value: 1,
        text_en: 'Own Organization',
        text_bn: 'নিজস্ব প্রতিষ্ঠানের'
    },
    {
        value: 2,
        text_en: 'Govt',
        text_bn: 'সরকারী'
    },
    {
        value: 3,
        text_en: 'Private',
        text_bn: 'ব্যক্তিগত'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    }
]
const genderList = [
    {
        value: '1',
        text_en: 'Male',
        text_bn: 'পুরুষ'
    },
    {
        value: '2',
        text_en: 'Female',
        text_bn: 'মহিলা'
    }
]
const roomUserTypeList = [
    {
        value: 1,
        text_en: 'Own Organization',
        text_bn: 'নিজস্ব প্রতিষ্ঠান'
    },
    {
        value: 2,
        text_en: 'Govt',
        text_bn: 'সরকারী'
    },
    {
        value: 3,
        text_en: 'Private',
        text_bn: 'ব্যক্তিগত'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    }
]

export default {
    paymentType,
    committeeType,
    jobTypeList,
    userPriceTypeList,
    genderList,
    sheduleType,
    roomUserTypeList
}
