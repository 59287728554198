const dashboardRoutes = (prop) => [
  {
      path: 'dashboard',
      name: prop + '.dashboard',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/Dashboard.vue')
  }
]
const applicationManagementRoutes = (prop) => [
  {
    path: 'application',
    name: prop + '.application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application/List.vue')
  },
  {
    path: 'received-application',
    name: prop + '.received_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/received-application/List.vue')
  },
  {
    path: 'assigned-application',
    name: prop + '.assigned_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/assigned-application/List.vue')
  },
  {
    path: 'approve-application',
    name: prop + '.approve_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/approve-application/List.vue')
  },
  {
    path: 'rejected-application',
    name: prop + '.rejected_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/rejected-application/List.vue')
  },
  {
    path: 'duty-calculator',
    name: prop + '.duty_calculator',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/duty-calculator/List.vue')
  },
  {
    path: 'feedback-rating',
    name: prop + '.feedback_rating',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/feedback-rating/List.vue')
  }
]

const routes = [
  {
    path: '/trade-tariff-service',
    name: 'trade_tariff_service',
    component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
    meta: { auth: false },
    children: dashboardRoutes('trade_tariff_service')
  },
  {
    path: '/trade-tariff-service/application-management',
    name: 'trade_tariff_service.application_management',
    component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
    meta: { auth: true },
    children: applicationManagementRoutes('trade_tariff_service.application_management')
  }
]

export default routes
