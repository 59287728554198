import actions from './actions'
import getters from './getters'
import custom from './custom'
import { mutations } from './mutations'

const state = {
  commonObj: {
    hasDropdownLoaded: false,
    masterAcidNameList: [],
    paymentTypeList: [],
    acidLicenseStepList: custom.acidLicenseStepList,
    formalinLicenseStepList: custom.formalinLicenseStepList,
    formalinClearanceStepList: custom.formalinClearanceStepList
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
