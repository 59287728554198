import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import custom from './custom'

const state = {
  isInsidePanel: false,
  externalPanels: [],
  externalMenus: [],
  cipPanel: {
    commonObj: {
      hasDropdownLoaded: false,
      // circularList: [],
      exportSectorList: [],
      venueList: [],
      circular: null
    },
    cipAppTypeList: custom.cipAppTypeList,
    latentExporterList: custom.latentExporterList,
    orgTypeList: custom.orgTypeList,
    merchandisingTypeList: custom.merchandisingTypeList,
    investmentTypeList: custom.investmentTypeList
  },
  bftiPanel: {
    commonObj: {
      hasDropdownLoaded: false,
      documentTypeList: [],
      researchRequestList: [],
      surveyList: []
    }
  },
  trainingPanel: {
    panel: 0,
    trainerOrTrainee: '',
    commonObj: {
      hasDropdownLoaded: false,
      paymentType: custom.paymentType,
      jobTypeList: custom.jobTypeList,
      userPriceTypeList: custom.userPriceTypeList,
      genderList: custom.genderList,
      sheduleType: custom.sheduleType,
      roomUserTypeList: custom.roomUserTypeList,
      committeeType: custom.committeeType,
      trainingTypeList: [],
      trainingTitleList: [],
      courseDocumentSetupList: [],
      faqSetupList: [],
      trainingQuarterSetupList: [],
      traineeEvaluationList: [],
      courseList: [],
      trainerEvaluationList: [],
      courseModuleList: [],
      personalInfoList: [],
      gradingStatus: [],
      trainingScheduleSetup: [],
      sessonType: [],
      trainingReportHeadList: [],
      nominatedOrgList: [],
      evaluationGradingList: [],
      batchList: []
    }
  },
  eBizPanel: {
    associationProfile: {},
    commonObj: {
      hasDropdownLoaded: false,
      councilInfoList: [],
      associationInfoList: [],
      proposalTypeList: [],
      productInfoList: [],
      typeOfBusinessList: [],
      projectNameList: []
    }
  },
  dealerPanel: {
    commonObj: {
      hasDropdownLoaded: false,
      paymentTypeList: custom.paymentTypeList,
      sellerTypeList: custom.sellerTypeList,
      applicationTypeList: custom.applicationTypeList,
      tcbStepList: custom.tcbStepList,
      maritalStatusList: custom.maritalStatusList,
      actionTypeList: custom.actionTypeList,
      serviceNamesList: [],
      stepNamesList: [],
      recruitmentNoticesList: [],
      warehouseList: [],
      cancelReasonList: []
    }
  },
  lrcpnPanel: {
    commonObj: {
      hasDropdownLoaded: false,
      masterAcidNameList: [],
      formalinStepList: custom.formalinStepList,
      actionTypeList: custom.actionTypeList,
      paymentTypeList: []
    }
  },
  teaGarden: {
    commonObj: {
      hasDropdownLoaded: false,
      feeTypesList: [],
      masterTreeList: [],
      masterFactoryTypeList: [],
      masterMadeTeaTypeList: [],
      factoryApplicationApplicantType: custom.factoryApplicationApplicantType,
      masterTeaGardenGenInfoList: [],
      pduCourseList: [],
      pduCircularList: [],
      masterProductionCapacityList: []
    }
  },
  tradeFair: {
    commonObj: {
      hasDropdownLoaded: false,
      fairSectorList: [],
      fairNameList: [],
      subsidyCategoryList: [],
      measurementUnitList: [],
      noteList: []
    },
    ditfCommonObj: {
      businessTypeList: [],
      dhakaFairList: [],
      stallCategoryList: [],
      securityMoneyList: []
    }
  },
  tradeTariff: {
    commonObj: {
      hasDropdownLoaded: false,
      tradeTariffCountryList: [],
      regionList: [],
      applicationTypeList: [],
      capitalInfoList: [],
      currencyList: [],
      productList: [],
      unitMeasurementList: [],
      sectionNoList: [],
      chapterNoList: [],
      headingList: [],
      coverageOfAgreementList: custom.coverageOfAgreementList,
      statusOfAgreementList: custom.statusOfAgreementList,
      portTypeList: custom.portTypeList,
      productTypeList: custom.productTypeList,
      subjectList: []
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
