const dashboardRoutes = (prop) => [
  {
      path: 'dashboard/:dashboard_name',
      name: prop + '.dashboard',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/MinistryDashboard.vue')
  }
]

const routes = [
  {
    path: '/ministry',
    name: 'ministry',
    component: () => import('@/layouts/MinistryDashboardLayout.vue'),
    meta: { auth: false },
    children: dashboardRoutes('ministry')
  }
]

export default routes
