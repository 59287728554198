
const setRoutes = (prop) => [
  {
    path: 'application-entry',
    name: prop + '.acid_import_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/acid-import-clearance-application/AdminList.vue')
  },
  {
    path: 'application-entry-form',
    name: prop + '.acid_import_from',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/acid-import-clearance-application/AdminForm.vue')
  },
  {
    path: 'application-entry-form/:id',
    name: prop + '.acid_import_edit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/acid-import-clearance-application/AdminForm.vue')
  },
  {
    path: 'application-entry-details/:id',
    name: prop + '.acid_import_details',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/acid-import-clearance-application/Details.vue')
  },
  {
    path: 'application-list',
    name: prop + '.application_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/application-list/List.vue')
  },
  {
    path: 'application-details/:id',
    name: prop + '.application_details',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/acid-import-clearance-application/Details.vue')
  },
  {
    path: 'forward',
    name: prop + '.application_forward',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../components/Forward.vue')
  },
  {
    path: 'forward-list',
    name: prop + '.forward_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/forward-list/List.vue')
  },
  {
    path: 'inspection-list',
    name: prop + '.inspection_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/inspection-list/List.vue')
  },
  {
    path: 'approval-list',
    name: prop + '.approval_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/approval-list/List.vue')
  },
  {
    path: 'approved-list',
    name: prop + '.approved_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/approved-list/List.vue')
  },
  {
    path: 'rejected-list',
    name: prop + '.rejected_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/rejected-list/List.vue')
  },
  {
    path: 'certificate/:id',
    name: prop + '.certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../../external-user-service/views/lrcpn-service-panel/pages/acid-import-clearance-application/Certificate.vue')
  }
]

const routes = [
  {
    path: '/lrcpn-service/acid-import',
    name: 'lrcpn_service.acid_import',
    component: () => import('@/layouts/LrcpnServiceLayout.vue'),
    meta: { auth: true },
    children: setRoutes('lrcpn_service.acid_import')
  }
]

export default routes
