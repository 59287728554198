  const TradeReportsRoutes = (prop) => [
    {
      path: 'application-report',
      name: prop + '.application_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/application-report/List.vue')
    },
    {
      path: 'assign-application-status-report',
      name: prop + '.assign_application_status_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/assign-application-status-report/List.vue')
    },
    {
      path: 'bilateral-trade-report',
      name: prop + '.bilateral_trade_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/bilateral-trade-report/List.vue')
    },
    {
      path: 'country-profile',
      name: prop + '.country_profile',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/country-profile/List.vue')
    },
    {
      path: 'potential-report',
      name: prop + '.potential_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/potential-report/List.vue')
    },
    {
      path: 'export-products-potential-report',
      name: prop + '.export_products_potential_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/export-products-to-a-specific-country-report/List.vue')
    },
    {
      path: 'concern-person-report',
      name: prop + '.concern_person_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/concern-person-report/List.vue')
    },
    {
      path: 'export-destinations-report',
      name: prop + '.export_destinations_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/export-destinations-report/List.vue')
    },
    {
      path: 'exporters-specific-country-report',
      name: prop + '.exporters_specific_country_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/exporters-specific-country-report/List.vue')
    },
    {
      path: 'importers-specific-country-report',
      name: prop + '.importers_specific_country_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/importers-specific-country-report/List.vue')
    },
    {
      path: 'import-destinations-report',
      name: prop + '.import_destinations_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/import-destinations-report/List.vue')
    },
    {
      path: 'import-products-specific-country-report',
      name: prop + '.import_products_specific_country_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/import-products-specific-country-report/List.vue')
    }
  ]

  const routes = [
    {
      path: '/trade-tariff-service/reports',
      name: 'trade_tariff_service.reports',
      component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
      meta: { auth: true },
      children: TradeReportsRoutes('trade_tariff_service.reports')
    }
  ]

export default routes
