const childRoutes = (prop) => [
    {
      path: 'training-type',
      name: prop + 'training_type',
      meta: { auth: true },
      component: () => import('../pages/training-type/List.vue')
    },
    {
      path: 'training-title',
      name: prop + 'training_title',
      meta: { auth: true },
      component: () => import('../pages/training-title/List.vue')
    },
    {
      path: 'pay-grade-setup',
      name: prop + 'pay_grade_setup',
      meta: { auth: true },
      component: () => import('../pages/pay-grade-setup/List.vue')
    },
    {
      path: 'course-document-setup',
      name: prop + 'course_document_setup',
      meta: { auth: true },
      component: () => import('../pages/course-document-setup/List.vue')
    },
    {
      path: 'faq-setup',
      name: prop + 'faq_setup',
      meta: { auth: true },
      component: () => import('../pages/faq-setup/List.vue')
    },
    {
      path: 'training-quarter-setup',
      name: prop + 'training_quarter_setup',
      meta: { auth: true },
      component: () => import('../pages/training-quarter-setup/List.vue')
    },
    {
      path: 'trainee-evaluation',
      name: prop + 'trainee_evaluation',
      meta: { auth: true },
      component: () => import('../pages/trainee-evaluation/List.vue')
    },
    {
      path: 'trainer-evaluation',
      name: prop + 'trainer_evaluation',
      meta: { auth: true },
      component: () => import('../pages/trainer-evaluation/List.vue')
    },
    {
      path: 'report-heading',
      name: prop + 'report_heading',
      meta: { auth: true },
      component: () => import('../pages/report-heading/List.vue')
    },
    {
      path: 'grading-status',
      name: prop + 'grading_status',
      meta: { auth: true },
      component: () => import('../pages/grading-status/List.vue')
    },
    {
      path: 'received-notifications',
      name: prop + 'received_notifications',
      meta: { auth: true },
      component: () => import('../pages/received-notifications/List.vue')
    },
    {
      path: 'schedule-setup',
      name: prop + 'schedule_setup',
      meta: { auth: true },
      component: () => import('../pages/schedule-setup/List.vue')
    },
    {
      path: 'course-coordinator',
      name: prop + 'course_coordinator',
      meta: { auth: true },
      component: () => import('../pages/course-coordinator/List.vue')
    },
    {
      path: 'batch',
      name: prop + '.batch',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/batch/List.vue')
    },
    {
      path: 'document-type',
      name: prop + '.document-type',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/document-type/List.vue')
    },
    {
      path: 'nominated-organization',
      name: prop + '.nominated_organization',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/nominated-organization/List.vue')
    },
    {
      path: 'evaluation-grade',
      name: prop + '.evaluation_grade',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/evaluation-grade/List.vue')
    },
    {
      path: 'guest-trainer',
      name: prop + 'guest_trainer',
      meta: { auth: true },
      component: () => import('../pages/guest-trainer/List.vue')
    }
]
const dashboardRoutes = (prop) => [
  {
      path: 'dashboard',
      name: prop + '.dashboard',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/Dashboard.vue')
  }
]
const routes = [
  {
    path: '/training-e-learning-service',
    name: 'training_e_learning_service.dashboard',
    component: () => import('@/layouts/TrainingServiceLayout.vue'),
    meta: { auth: true },
    children: dashboardRoutes('training_e_service.dashboard')
  },
  {
    path: '/training-e-learning-service/configuration',
    name: 'training_e_learning_service.configuration',
    component: () => import('@/layouts/TrainingServiceLayout.vue'),
    meta: { auth: true },
    children: childRoutes('training_e_service.barc.configuration')
  }
]

export default routes
