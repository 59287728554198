const coverageOfAgreementList = [
  {
    value: 1,
    text: 'Goods',
    text_en: 'Goods',
    text_bn: 'পণ্যদ্রব্য'
  },
  {
    value: 2,
    text: 'Services',
    text_en: 'Services',
    text_bn: 'সেবা'
  },
  {
    value: 3,
    text: 'Goods & Services',
    text_en: 'Goods & Services',
    text_bn: 'পণ্য ও সেবা'
  }
]
const statusOfAgreementList = [
  {
    value: 1,
    text: 'Early Announcement Under Negotiation',
    text_en: 'Early Announcement Under Negotiation',
    text_bn: 'আলোচনার অধীনে প্রাথমিক ঘোষণা'
  },
  {
    value: 2,
    text: 'Early Announcement Signed',
    text_en: 'Early Announcement Signed',
    text_bn: 'প্রারম্ভিক ঘোষণা স্বাক্ষরিত'
  },
  {
    value: 3,
    text: 'Not Yet In Force',
    text_en: 'Not Yet In Force',
    text_bn: 'এখনো বলবৎ নয়'
  },
  {
    value: 4,
    text: 'In Force For At Least One Party',
    text_en: 'In Force For At Least One Party',
    text_bn: 'অন্তত একটি দলের জন্য বাহিনী'
  },
  {
    value: 5,
    text: 'In Force',
    text_en: 'In Force',
    text_bn: 'বলবৎ'
  },
  {
    value: 6,
    text: 'Inactive',
    text_en: 'Inactive',
    text_bn: 'নিষ্ক্রিয়'
  }
]
const cipAppTypeList = [
    {
        value: 1,
        text_en: 'Single',
        text_bn: 'একক'
    },
    {
        value: 2,
        text_en: 'Group',
        text_bn: 'গ্রুপ'
    }
]
const latentExporterList = [
    {
        value: 1,
        text_en: 'Yes',
        text_bn: 'হ্যাঁ'
    },
    {
        value: 2,
        text_en: 'No',
        text_bn: 'না'
    },
    {
        value: 3,
        text_en: 'Partial',
        text_bn: 'আংশিক'
    }
]
const orgTypeList = [
    {
        value: 1,
        text_en: 'Private Ltd',
        text_bn: 'প্রাইভেট লিমিটেড'
    },
    {
        value: 2,
        text_en: 'Public Ltd',
        text_bn: 'পাবলিক লিমিটেড'
    },
    {
        value: 3,
        text_en: 'Sole Proprietorship',
        text_bn: 'একক মালিকানা'
    },
    {
      value: 4,
      text_en: 'Partnership Business',
      text_bn: 'অংশীদারি ব্যবসা'
    },
    {
      value: 5,
      text_en: 'NGO',
      text_bn: 'এনজিও'
    },
    {
      value: 6,
      text_en: 'Cooperative Societies',
      text_bn: 'সমবায় সমিতি'
    },
    {
      value: 7,
      text_en: 'Statutory Body',
      text_bn: 'বিধিবদ্ধ সংস্থা'
    }
]
const merchandisingTypeList = [
    {
        value: 1,
        text_en: 'Textile Factory',
        text_bn: 'টেক্সটাইল ফ্যাক্টরি'
    },
    {
        value: 2,
        text_en: 'Others',
        text_bn: 'অন্যান্য'
    }
]
const paymentTypeList = [
    {
        value: 1,
        text_en: 'Registration Fee',
        text_bn: 'আবেদন ফি',
        amount: 5000,
        step_id: 0
    },
    {
        value: 2,
        text_en: 'Security Money',
        text_bn: 'জামানত ফি',
        amount: 30000,
        step_id: 4
    },
    {
        value: 3,
        text_en: 'License Fee',
        text_bn: 'লাইসেন্স ফি',
        amount: 10000,
        step_id: 4
    },
    {
        value: 4,
        text_en: 'Renewal Fee',
        text_bn: 'নবায়ন ফি',
        amount: 10000,
        step_id: 0
    },
    {
        value: 5,
        text_en: 'Late Fee',
        text_bn: 'বিলম্ব ফি',
        amount: 1000,
        step_id: 0
    },
    {
        value: 6,
        text_en: 'Allocation Fee',
        text_bn: 'বরাদ্দ ফি',
        amount: 0,
        step_id: 5
    }
]
const sellerTypeList = [
    { value: 1, text_en: 'Temporary', text_bn: 'সাময়িক' },
    { value: 2, text_en: 'Permanent', text_bn: 'স্থায়ী' }
  ]
  const applicationTypeList = [
    { value: 1, text_en: 'New', text_bn: 'নতুন' },
    { value: 2, text_en: 'Renew', text_bn: 'নবায়ন' },
    { value: 3, text_en: 'Old', text_bn: 'পুরাতন' }
  ]
  const tcbStepList = [
    { value: 0, text_en: 'Application List', text_bn: 'আবেদনের তালিকা' },
    { value: 1, text_en: 'Document Verification', text_bn: 'ডকুমেন্ট ভেরিফিকেশন' },
    { value: 2, text_en: 'Inspection', text_bn: 'পরিদর্শন' },
    { value: 3, text_en: 'Approval', text_bn: 'অনুমোদন' },
    { value: 4, text_en: 'Agrement', text_bn: 'চুক্তি' },
    { value: 5, text_en: 'Archive', text_bn: 'আর্কাইভ' }
  ]
  const formalinStepList = [
    { value: 1, text_en: 'Application List', text_bn: 'আবেদনের তালিকা' },
    { value: 2, text_en: 'Document Verification', text_bn: 'ডকুমেন্ট ভেরিফিকেশন' },
    { value: 3, text_en: 'Inspection', text_bn: 'পরিদর্শন' },
    { value: 4, text_en: 'Approval', text_bn: 'অনুমোদন' }
  ]
  const maritalStatusList = [
    { text_en: 'Married', text_bn: 'বিবাহিত', value: 1 },
    { text_en: 'Unmarried', text_bn: 'অবিবাহিত', value: 2 }
  ]
  const actionTypeList = [
    { text_en: 'Resubmit', text_bn: 'রি-সাবমিট', value: 3 },
    { text_en: 'Reject', text_bn: 'প্রত্যাখ্যান', value: 2 }
  ]
  const factoryApplicationApplicantType = [
    {
      value: 1,
      text: 'Person',
      text_en: 'Person',
      text_bn: 'ব্যাক্তিগত'
    },
    {
      value: 2,
      text: 'Limited Company',
      text_en: 'Limited Company',
      text_bn: 'লিমিটেড কোম্পানি'
    },
    {
      value: 3,
      text: 'Partnership Business',
      text_en: 'Partnership Business',
      text_bn: 'অংশীদারি ব্যবসা'
    },
    {
      value: 4,
      text: 'Cooperative Society',
      text_en: 'Cooperative Society',
      text_bn: 'সমবায় সমিতি'
    }
  ]
  const stallTypeList = [
    {
      value: 1,
      text: 'Corner',
      text_en: 'Corner',
      text_bn: 'কর্ণার '
    },
    {
      value: 2,
      text: 'Regular',
      text_en: 'Regular',
      text_bn: 'রেগুলার '
    }
  ]
  const portTypeList = [
    {
      value: 1,
      text: 'Land port',
      text_en: 'Land port',
      text_bn: 'স্থলবন্দর'
    },
    {
      value: 2,
      text: 'Sea port',
      text_en: 'Sea port',
      text_bn: 'সমুদ্র বন্দর'
    },
    {
      value: 3,
      text: 'Airport',
      text_en: 'Airport',
      text_bn: 'বিমানবন্দর'
    }
  ]
  const productTypeList = [
    {
      value: 1,
      text: 'Agriculture',
      text_en: 'Agriculture',
      text_bn: 'কৃষি'
    },
    {
      value: 2,
      text: 'Industrial',
      text_en: 'Industrial',
      text_bn: 'শিল্প'
    }
  ]
  const paymentType = [
    {
        value: 'Trainer',
        text_en: 'Trainer',
        text_bn: 'প্রশিক্ষক'
    },
    {
        value: 'Trainee',
        text_en: 'Trainee',
        text_bn: 'প্রশিক্ষণার্থী'
    }
]
const committeeType = [
    {
        value: 1,
        text_en: 'Course Management Team',
        text_bn: 'কোর্স ম্যানেজমেন্ট টিম'
    },
    {
        value: 2,
        text_en: 'Executive Management Team',
        text_bn: 'এক্সিকিউটিভ ম্যানেজমেন্ট টিম'
    }
]
const sheduleType = [
    {
        value: 'Other',
        text_en: 'Other',
        text_bn: 'অন্যান্য'
    },
    {
        value: 'Trainer',
        text_en: 'Trainer',
        text_bn: 'প্রশিক্ষক'
    }
]
const jobTypeList = [
    {
        value: '1',
        text_en: 'Cader',
        text_bn: 'ক্যাডার'
    },
    {
        value: '2',
        text_en: 'Non-Cader',
        text_bn: 'নন-ক্যাডার'
    }
]
const userPriceTypeList = [
    {
        value: 1,
        text_en: 'Own Organization',
        text_bn: 'নিজস্ব প্রতিষ্ঠানের'
    },
    {
        value: 2,
        text_en: 'Govt',
        text_bn: 'সরকারী'
    },
    {
        value: 3,
        text_en: 'Private',
        text_bn: 'ব্যক্তিগত'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    }
]
const genderList = [
    {
        value: '1',
        text_en: 'Male',
        text_bn: 'পুরুষ'
    },
    {
        value: '2',
        text_en: 'Female',
        text_bn: 'মহিলা'
    }
]
const roomUserTypeList = [
    {
        value: 1,
        text_en: 'Own Organization',
        text_bn: 'নিজস্ব প্রতিষ্ঠান'
    },
    {
        value: 2,
        text_en: 'Govt',
        text_bn: 'সরকারী'
    },
    {
        value: 3,
        text_en: 'Private',
        text_bn: 'ব্যক্তিগত'
    },
    {
        value: 4,
        text_en: 'Domestic and Foreign Consultants',
        text_bn: 'দেশী-বিদেশী পরামর্শক'
    }
]
const investmentTypeList = [
  {
    value: 1,
    text_en: 'Local',
    text_bn: 'দেশি'
  },
  {
      value: 2,
      text_en: 'Foreign',
      text_bn: 'বিদেশি'
  },
  {
      value: 3,
      text_en: 'Joint Venture',
      text_bn: 'জয়েন্ট ভেঞ্জার'
  }
]

export default {
    cipAppTypeList,
    latentExporterList,
    orgTypeList,
    merchandisingTypeList,
    paymentTypeList,
    sellerTypeList,
    applicationTypeList,
    tcbStepList,
    formalinStepList,
    maritalStatusList,
    actionTypeList,
    factoryApplicationApplicantType,
    stallTypeList,
    coverageOfAgreementList,
    statusOfAgreementList,
    portTypeList,
    paymentType,
    committeeType,
    jobTypeList,
    userPriceTypeList,
    genderList,
    sheduleType,
    roomUserTypeList,
    productTypeList,
    investmentTypeList
}
