import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'
import Custom from './custom'

const state = {
  currentFiscalYearId: 0,
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    trainingTypeList: [],
    trainingTitleList: [],
    courseDocumentSetupList: [],
    faqSetupList: [],
    trainingQuarterSetupList: [],
    foodPackageList: [],
    traineeEvaluationList: [],
    venus: [],
    courseList: [],
    trainerEvaluationList: [],
    courseModuleList: [],
    guestHouseList: [],
    paymentType: Custom.paymentType,
    jobTypeList: Custom.jobTypeList,
    userPriceTypeList: Custom.userPriceTypeList,
    genderList: Custom.genderList,
    sheduleType: Custom.sheduleType,
    roomUserTypeList: Custom.roomUserTypeList,
    committeeType: Custom.committeeType,
    gradingStatus: [],
    floorList: [],
    trainingScheduleSetup: [],
    sessonType: [],
    trainingReportHeadList: [],
    nominatedOrgList: [],
    batchList: []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
