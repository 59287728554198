const btbRoutes = (prop) => [
  {
    path: 'machine-name',
    name: prop + '.machine_name',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/configuration/machine-name/List.vue')
  },
  {
    path: 'course',
    name: prop + '.course',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course/List.vue')
  },
  // those routes for only test porpose
  {
    path: 'evaluation-report',
    name: prop + '.evaluation-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report/evaluationReport.vue')
  },
  // those routes for test porpose end
  {
    path: 'prepare-circular',
    name: prop + '.prepare_circular',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circular/List.vue')
  },
  {
    path: 'circular-request',
    name: prop + '.prepare_circular',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circular/RequestList.vue')
  },
  {
    path: 'approve-circular',
    name: prop + '.prepare_circular',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circular/ApprovedList.vue')
  },
  {
    path: 'circular/:id',
    name: prop + '.circular_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/circular/View.vue')
  },
  // course management routes
  {
    path: 'course-application',
    name: prop + '.course_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/List.vue')
  },
  {
    path: 'course-application-form',
    name: prop + '.course_application_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Form.vue')
  },
  {
    path: 'course-application-view/:id',
    name: prop + '.course_application_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Details.vue')
  },
  {
    path: 'course-application-certificate/:id',
    name: prop + '.course_application_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Certificate.vue')
  },
  {
    path: 'course-application-verification-list',
    name: prop + '.course_application_verification_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/VerificationList.vue')
  },
  {
    path: 'course-application-confirmed-list',
    name: prop + '.course_application_confirmed_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/ConfirmedList.vue')
  },
  {
    path: 'course-application-certified-list',
    name: prop + '.course_application_certified_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/CertifiedList.vue')
  },
  // payment routes
  {
    path: 'payment/:id',
    name: prop + '.payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Payment/Form.vue')
  },
  {
    path: 'test/payment/success',
    name: prop + '.payment_success',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Payment/Success.vue')
  },
  {
    path: 'test/payment/cancel',
    name: prop + '.payment_cancel',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Payment/Cancel.vue')
  },
  {
    path: 'test/payment/decline',
    name: prop + '.payment_decline',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Payment/Decline.vue')
  },
  // reports
  {
    path: 'participant-report',
    name: prop + '.participant_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/course-management/Report/Participant.vue')
  },
  // Education Trust scholarship
  {
    path: 'online-education-application-form',
    name: prop + '.online_education_application_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/education-trust-scholarship/application-list/Form.vue')
  },
  {
    path: 'online-education-application-list',
    name: prop + '.online_education_application_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/education-trust-scholarship/application-list/List.vue')
  },
  {
    path: 'received-list',
    name: prop + '.received_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/education-trust-scholarship/received-list/List.vue')
  },
  {
    path: 'forward-list',
    name: prop + '.forward_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/education-trust-scholarship/forward-list/List.vue')
  },
  {
    path: 'approve-list',
    name: prop + '.approve_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/education-trust-scholarship/approve-list/List.vue')
  },
  {
    path: 'reject-list',
    name: prop + '.reject_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/education-trust-scholarship/reject-list/List.vue')
  },
  {
    path: 'student-scholarship-report',
    name: prop + '.student_scholarship_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report/student-scholarship-report/List.vue')
  },
  // Regular Garden Inspection
  {
    path: 'prepare-schedule',
    name: prop + '.prepare_schedule_create',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/prepare-schedule/Form.vue')
  },
  {
    path: 'prepare-schedule/:id',
    name: prop + '.prepare_schedule_edit',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/prepare-schedule/Form.vue')
  },
  {
    path: 'prepare-schedule-view/:id',
    name: prop + '.prepare_schedule_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/prepare-schedule/Details.vue')
  },
  {
    path: 'prepare-schedule-list',
    name: prop + '.prepare_schedule_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/prepare-schedule/List.vue')
  },
  {
    path: 'prepare-m-e-report',
    name: prop + '.prepare_m_e_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/prepare-m-e-report/List.vue')
  },
  {
    path: 'prepare-m-e-report/:id',
    name: prop + '.prepare_garden_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/prepare-m-e-report/prepareGardenList.vue')
  },
  {
    path: 'prepare-m-e-form/:id',
    name: prop + '.prepare_m_e_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/prepare-m-e-report/evaluationReport.vue')
  },
  {
    path: 'approve-m-e-report',
    name: prop + '.approve_m_e_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/approve-m-e-report/List.vue')
  },
  {
    path: 'approve-m-e-report/:id',
    name: prop + '.approve_m_e_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/approve-m-e-report/approveList.vue')
  },
  {
    path: 'garden-inspection-report',
    name: prop + '.garden_inspection_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/regular-garden-inspection/garden-inspection-report/List.vue')
  }
]

const routes = [
  {
    path: '/tea-garden-service/pdu',
    name: 'tea_garden_service.pdu',
    component: () => import('@/layouts/TeaGardenServiceLayout.vue'),
    meta: { auth: true },
    children: btbRoutes('tea_garden_service.pdu')
  }
]

export default routes
