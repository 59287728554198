export default {
  mutateTrainingElearningCommonProperties ({ commit }, payload) {
    commit('mutateTrainingElearningCommonProperties', payload)
  },
  localizeDropdown ({ commit }, payload) {
    commit('localizeDropdown', payload)
  },
  setCurrentFiscalYearId ({ commit }, payload) {
    commit('setCurrentFiscalYearId', payload)
  }
}
