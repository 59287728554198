const childRoutes = (prop) => [
    {
        path: 'database-backup',
        name: prop + '.database-backup',
        meta: { auth: true, name: 'Editable' },
        component: () => import('../pages/database-backup/List.vue')
    }
]

const routes = [
    {
        path: '/common-service/database-dump',
        name: 'common_service.database_dump',
        component: () => import('@/layouts/CommonServiceLayout.vue'),
        meta: { auth: false },
        children: childRoutes('common_service.database_dump') // test
    }
]

export default routes
