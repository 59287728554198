const childRoutes = (prop) => [
  {
    path: 'nominated-trainee-trainer-form',
    name: prop + 'nominated_trainee_trainer_form',
    meta: { auth: true },
    component: () => import('../pages/nominated-trainee-trainer/Form.vue')
  },
  {
    path: 'trainee-selection',
    name: prop + 'trainee_selection',
    meta: { auth: true },
    component: () => import('../pages/trainee-selection/List.vue')
  },
  {
    path: 'trainer-selection',
    name: prop + 'trainer_selection',
    meta: { auth: true },
    component: () => import('../pages/trainer-selection/List.vue')
  },
  {
    path: 'batch-assign',
    name: prop + 'batch_assign',
    meta: { auth: true },
    component: () => import('../pages/batch-assign/List.vue')
  },
  {
    path: 'batch_assign-form',
    name: prop + 'batch_assign_form',
    meta: { auth: true },
    component: () => import('../pages/batch-assign/Form.vue')
  },
  {
    path: 'allowance',
    name: prop + 'allowance_list',
    meta: { auth: true },
    component: () => import('../pages/allowance/List.vue')
  },
  {
    path: 'allowance-form',
    name: prop + 'allowance_form',
    meta: { auth: true },
    component: () => import('../pages/allowance/Form.vue')
  },
  {
    path: 'allowance-details',
    name: prop + 'allowance_details',
    meta: { auth: true },
    component: () => import('../pages/allowance/Details.vue')
  },
  {
    path: 'trainee-selection-form',
    name: prop + 'trainee_selection_form',
    meta: { auth: true },
    component: () => import('../pages/trainee-selection/Form.vue')
  },
  {
    path: 'trainer-selection-form',
    name: prop + 'trainer_selection_form',
    meta: { auth: true },
    component: () => import('../pages/trainer-selection/Form.vue')
  },
  {
    path: 'manual-course-evaluation',
    name: prop + 'manual_course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/manual-course-evaluation/List.vue')
  },
  {
    path: 'post-course-evaluation',
    name: prop + 'post_course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/post-course-evaluation/List.vue')
  },
  {
    path: 'manual-course-evaluation-form',
    name: prop + 'manual_course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/manual-course-evaluation/Form.vue')
  },
  {
    path: 'post-course-evaluation-form',
    name: prop + 'post_course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/post-course-evaluation/Form.vue')
  },
  {
    path: 'manual-course-evaluation-details',
    name: prop + 'manual_course_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/manual-course-evaluation/Details.vue')
  },
  {
    path: 'post-course-evaluation-details',
    name: prop + 'post_course_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/post-course-evaluation/Details.vue')
  },
  {
    path: 'bill-payment',
    name: prop + 'bill_payment_list',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/List.vue')
  },
  {
    path: 'bill-payment-details',
    name: prop + 'bill_payment_details',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/Details.vue')
  },
  {
    path: 'bill-payment/:status',
    name: prop + '.bill_payment_list.status',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/List.vue')
  },
  {
    path: 'bill-payment-form',
    name: prop + 'bill_payment-form',
    meta: { auth: true },
    component: () => import('../pages/bill-payment/Form.vue')
  },
  {
    path: 'trainee-id-card',
    name: prop + 'trainee_id_card',
    meta: { auth: true },
    component: () => import('../pages/trainee-id-card/List.vue')
  },
  {
    path: 'attendance',
    name: prop + 'attendance',
    meta: { auth: true },
    component: () => import('../pages/attendance/List.vue')
  },
  {
    path: 'attendance-details',
    name: prop + 'attendance_details',
    meta: { auth: true },
    component: () => import('../pages/attendance/AttendenceDetails.vue')
  },
  {
    path: 'attendance-form',
    name: prop + 'attendance_form',
    meta: { auth: true },
    component: () => import('../pages/attendance/Form.vue')
  },
  {
    path: 'evaluation',
    name: prop + 'evaluation',
    meta: { auth: true },
    component: () => import('../pages/evaluation/List.vue')
  },
  {
    path: 'evaluation-form',
    name: prop + 'evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/evaluation/Form.vue')
  },
  {
    path: 'course-evaluation',
    name: prop + 'course_evaluation',
    meta: { auth: true },
    component: () => import('../pages/course-evaluation/List.vue')
  },
  {
    path: 'course-evaluation-form',
    name: prop + 'course_evaluation_form',
    meta: { auth: true },
    component: () => import('../pages/course-evaluation/Form.vue')
  },
  {
    path: 'disciplinary-action',
    name: prop + 'disciplinary_action',
    meta: { auth: true },
    component: () => import('../pages/disciplinary-action/List.vue')
  },
  {
    path: 'trainee-evaluation',
    name: prop + 'trainee_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainee-evaluation/List.vue')
  },
  {
    path: 'trainer-evaluation',
    name: prop + 'trainer_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainer-evaluation/List.vue')
  },
  {
    path: 'trainer-evaluation-details',
    name: prop + 'trainer_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/trainer-evaluation/Details.vue')
  },
  {
    path: 'certificate-generate-list',
    name: prop + 'certificate_generate_list',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/List.vue')
  },
  {
    path: 'certificate-list',
    name: prop + 'certificate_list',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/List.vue')
  },
  {
    path: 'certificate-generate-form',
    name: prop + 'certificate_generate_form',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/Form.vue')
  },
  {
    path: 'certificate-generate-details',
    name: prop + 'certificate_generate_details',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/Details.vue')
  },
  {
    path: 'certificate-generate-details-two',
    name: prop + 'certificate_generate_details_two',
    meta: { auth: true },
    component: () => import('../pages/certificate-generate/DetailsTwo.vue')
  },
  {
    path: 'certificate',
    name: prop + 'certificate',
    meta: { auth: true },
    component: () => import('../pages/certificate-list/certificateView.vue')
  },
  {
    path: 'trainer-wise-evaluation',
    name: prop + 'trainer_wise_evaluation',
    meta: { auth: true },
    component: () => import('../pages/trainer-wise-evaluation/List.vue')
  },
  {
    path: 'trainer-wise-evaluation-details',
    name: prop + 'trainer_wise_evaluation_details',
    meta: { auth: true },
    component: () => import('../pages/trainer-wise-evaluation/Details.vue')
  },
  {
    path: 'complains',
    name: prop + 'complains',
    meta: { auth: true },
    component: () => import('../pages/complains/List.vue')
  }
]

const routes = [
    {
      path: '/training-e-learning-service/tim',
      name: 'training_e_learning_service.tim',
      component: () => import('@/layouts/TrainingServiceLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.tim')
    }
]

export default routes
