const childRoutes = (prop) => [
    {
      path: 'circular-publication',
      name: prop + 'circular_publication',
      meta: { auth: true },
      component: () => import('../pages/circular-publication/List.vue')
    },
    {
      path: 'circular-publication-forward-list',
      name: prop + 'circular_publication_forward_list',
      meta: { auth: true },
      component: () => import('../pages/circular-publication/RequestList.vue')
    },
    {
      path: 'circular-publication-approve-list',
      name: prop + 'circular_publication_approve_list',
      meta: { auth: true },
      component: () => import('../pages/circular-publication/ApproveList.vue')
    },
    {
      path: 'registration',
      name: prop + 'registration',
      meta: { auth: true },
      component: () => import('../pages/registration/List.vue')
    },
    {
      path: 'registration-form',
      name: prop + 'registration_form',
      meta: { auth: true },
      component: () => import('../pages/registration/Form.vue')
    },
    {
      path: 'training-application',
      name: prop + 'training_application',
      meta: { auth: true },
      component: () => import('../pages/training-application/List.vue')
    },
    {
      path: 'request-document',
      name: prop + 'request_document',
      meta: { auth: true },
      component: () => import('../pages/request-document/List.vue')
    },
    {
      path: 'complain-list',
      name: prop + 'complain_list',
      meta: { auth: true },
      component: () => import('../pages/complains/List.vue')
    },
    {
      path: 'nominated-org-payment',
      name: prop + 'nominated_org_payment',
      meta: { auth: true },
      component: () => import('../pages/nominated-org-payment/List.vue')
    }
]

const routes = [
    {
      path: '/training-e-learning-service/iabm',
      name: 'training_e_learning_service.iabm',
      component: () => import('@/layouts/TrainingServiceLayout.vue'),
      meta: { auth: true },
      children: childRoutes('training_e_service.barc.configuration')
    }
]

export default routes
