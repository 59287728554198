const portTypeList = [
  {
    value: 1,
    text: 'Land port',
    text_en: 'Land port',
    text_bn: 'স্থলবন্দর'
  },
  {
    value: 2,
    text: 'Sea port',
    text_en: 'Sea port',
    text_bn: 'সমুদ্র বন্দর'
  },
  {
    value: 3,
    text: 'Airport',
    text_en: 'Airport',
    text_bn: 'বিমানবন্দর'
  }
]
const coverageOfAgreementList = [
  {
    value: 1,
    text: 'Goods',
    text_en: 'Goods',
    text_bn: 'পণ্যদ্রব্য'
  },
  {
    value: 2,
    text: 'Services',
    text_en: 'Services',
    text_bn: 'সেবা'
  },
  {
    value: 3,
    text: 'Goods & Services',
    text_en: 'Goods & Services',
    text_bn: 'পণ্য ও সেবা'
  }
]
const statusOfAgreementList = [
  {
    value: 1,
    text: 'Early Announcement Under Negotiation',
    text_en: 'Early Announcement Under Negotiation',
    text_bn: 'আলোচনার অধীনে প্রাথমিক ঘোষণা'
  },
  {
    value: 2,
    text: 'Early Announcement Signed',
    text_en: 'Early Announcement Signed',
    text_bn: 'প্রারম্ভিক ঘোষণা স্বাক্ষরিত'
  },
  {
    value: 3,
    text: 'Not Yet In Force',
    text_en: 'Not Yet In Force',
    text_bn: 'এখনো বলবৎ নয়'
  },
  {
    value: 4,
    text: 'In Force For At Least One Party',
    text_en: 'In Force For At Least One Party',
    text_bn: 'অন্তত একটি দলের জন্য বাহিনী'
  },
  {
    value: 5,
    text: 'In Force',
    text_en: 'In Force',
    text_bn: 'বলবৎ'
  },
  {
    value: 6,
    text: 'Inactive',
    text_en: 'Inactive',
    text_bn: 'নিষ্ক্রিয়'
  }
]
const productTypeList = [
  {
    value: 1,
    text: 'Agriculture',
    text_en: 'Agriculture',
    text_bn: 'কৃষি'
  },
  {
    value: 2,
    text: 'Industrial',
    text_en: 'Industrial',
    text_bn: 'শিল্প'
  }
]
export default {
  portTypeList,
  coverageOfAgreementList,
  statusOfAgreementList,
  productTypeList
}
