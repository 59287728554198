const btbRoutes = (prop) => [
  {
    path: 'tea-farmer-application',
    name: prop + '.received_application',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-farmer-reg/List.vue')
  },
  // those routes for only test porpose
  {
    path: 'online-tea-license',
    name: prop + '.online-tea-license',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/license/OnlineTeaLicense.vue')
  },
  {
    path: 'tea-farmer-application-form',
    name: prop + '.tea_farmer_application_create',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-farmer-reg/Form.vue')
  },
  {
    path: 'tea-farmer-application-renew-form/:parent_id',
    name: prop + '.tea_farmer_application_renew_create',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-farmer-reg/RenewForm.vue')
  },
  {
    path: 'tea-farmer-application-view/:id',
    name: prop + '.tea_farmer_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('../ApplicationDetails/SmallTeaFarmer/Details.vue')
  },
  {
    path: 'small-farmer-certificate/:id',
    name: prop + '.small_farmer_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../ApplicationDetails/SmallTeaFarmer/Certificate.vue')
  },
  {
    path: 'certificate',
    name: prop + '.certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/Certificate.vue')
  },
  {
    path: 'registration-copy',
    name: prop + '.registration-copy',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/RegistrationCopy.vue')
  },
  {
    path: 'registration',
    name: prop + '.registration',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/Registration.vue')
  },
  {
    path: 'tree-disposal-registration-form',
    name: prop + '.tree_disposal_registration_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tree-disposal-noc/Form.vue')
  },
  {
    path: 'tree-disposal-registration',
    name: prop + '.tree_disposal_registration',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tree-disposal-noc/List.vue')
  },
  {
    path: 'tree-disposal-registration-view/:id',
    name: prop + '.tree_disposal_registration_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../ApplicationDetails/TreeDisposalNoc/Details.vue')
  },
  {
    path: 'tree-disposal-certificate/:id',
    name: prop + '.tree_disposal_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../ApplicationDetails/TreeDisposalNoc/Certificate.vue')
  },
  {
    path: 'return-form1',
    name: prop + '.return-form1',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/ReturnForm1.vue')
  },
  {
    path: 'return-form2',
    name: prop + '.return-form1',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/ReturnForm2.vue')
  },
  {
    path: 'return-form3',
    name: prop + '.return-form1',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/ReturnForm3.vue')
  },
  {
    path: 'factory-registration',
    name: prop + '.factory-registration',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/FactoryRegistration.vue')
  },
  {
    path: 'res-certificate',
    name: prop + '.res-certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/ResCertificate.vue')
  },
  {
    path: 'tea-course1',
    name: prop + '.tea-course1',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/TeaCourse1.vue')
  },
  {
    path: 'tea-tasting',
    name: prop + '.tea-tasting',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/TeaTasting.vue')
  },
  {
    path: 'tpc-course',
    name: prop + '.tpc-course',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/TpcCourse.vue')
  },
  {
    path: 'factory-reg',
    name: prop + '.factory-reg',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/FactoryReg.vue')
  },
  {
    path: 'distribution-report',
    name: prop + '.distribution-report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/certificates/SeedDistReport.vue')
  },
  {
    path: 'tea-garden-application',
    name: prop + '.tea_garden_application',
    meta: { auth: true },
    component: () => import('../pages/tea-garden-application/List.vue')
  },
  {
    path: 'tea-garden-application-form',
    name: prop + '.tea_garden_application_form',
    meta: { auth: true },
    props: true,
    component: () => import('../pages/tea-garden-application/Form.vue')
  },
  {
    path: 'tea-garden-application/:id',
    name: prop + '.tea_garden_application_show',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../ApplicationDetails/TeaGarden/Details.vue')
  },
  {
    path: 'tea-garden-application-certificate/:id',
    name: prop + '.tea_garden_application_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../ApplicationDetails/TeaGarden/Certificate.vue')
  },
  {
    path: 'tea-garden-renew-application-form/:id',
    name: prop + '.tea_garden_renew_application_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-garden-application/RenewForm.vue')
  },
  // tea factory applications routes form external panel
  {
    path: 'tea-factory-application',
    name: prop + '.tea_factory_application',
    meta: { auth: true },
    component: () => import('../../../external-user-service/views/tea-garden-panel/pages/tea-factory-application/ListAdmin.vue')
  },
  {
    path: 'tea-factory-application-form',
    name: prop + '.tea_factory_application_form',
    meta: { auth: true },
    props: true,
    component: () => import('../../../external-user-service/views/tea-garden-panel/pages/tea-factory-application/FormAdmin.vue')
  },
  {
    path: 'tea-factory-application-renew-form/:parent_id',
    name: prop + '.tea_factory_application_renew_form',
    meta: { auth: true },
    props: true,
    component: () => import('../../../external-user-service/views/tea-garden-panel/pages/tea-factory-application/RenewFormAdmin.vue')
  },
  {
    path: 'tea-factory-certificate/:id',
    name: prop + '.tea_factory_certificate',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../ApplicationDetails/TeaFactory/Certificate.vue')
  },
  {
    path: 'tea-factory-application-view/:id',
    name: prop + '.tea_factory_application_view',
    meta: { auth: true },
    props: true,
    component: () => import('../ApplicationDetails/TeaFactory/Details')
  },
  // payments routes
  {
    path: 'payment/:id',
    name: prop + '.payment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/Payment/Payment.vue')
  },
  {
    path: 'service/payment/success',
    name: prop + '.payment_success',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/Payment/Success.vue')
  },
  {
    path: 'service/payment/cancel',
    name: prop + '.payment_cancel',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/Payment/Cancel.vue')
  },
  {
    path: 'service/payment/decline',
    name: prop + '.payment_decline',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/Payment/Decline.vue')
  },
  // payment route end
  // btb circular route
  {
    path: 'circular',
    name: prop + '.circular',
    meta: { auth: true },
    component: () => import('../pages/fertilizer-circular/List.vue')
  },
  // Ex-Garden view route
  {
    path: 'ex-garden-application/:id',
    name: prop + '.ex_garden_application_show',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../ApplicationDetails/ExGarden/Details.vue')
  },
  // Fertilizer Request Process
  {
    path: 'indent-circular-list',
    name: prop + '.indent_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularAppList.vue')
  },
  {
    path: 'indent-submission-list',
    name: prop + '.indent_submission_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/List.vue')
  },
  {
    path: 'indent-submissions-view/:id',
    name: prop + '.indent_submission_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../../garden/pages/fertilizer-request/online-application/Details.vue')
  },
  {
    path: 'indent-received-circular-list',
    name: prop + '.indent_received_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularReceivedList.vue')
  },
  {
    path: 'indent-received-list',
    name: prop + '.indent_received_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/ReceivedList.vue')
  },
  {
    path: 'indent-forwarded-circular-list',
    name: prop + '.indent_forwarded_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularForwardedList.vue')
  },
  {
    path: 'indent-forwarded-list',
    name: prop + '.indent_forwarded_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/ForwardedList.vue')
  },
  {
    path: 'indent-verified-circular-list',
    name: prop + '.indent_verified_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularVerifiedList.vue')
  },
  {
    path: 'indent-verified-list',
    name: prop + '.indent_verified_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/VerifiedList.vue')
  },
  {
    path: 'indent-approved-circular-list',
    name: prop + '.indent_approved_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularApprovedList.vue')
  },
  {
    path: 'indent-approved-list',
    name: prop + '.indent_approved_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/ApprovedList.vue')
  },
  {
    path: 'fertilizer-allocated-circular-list',
    name: prop + '.fertilizer_allocated_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularAllocatedList.vue')
  },
  {
    path: 'indent-allocated-list',
    name: prop + '.indent_allocated_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/AllocatedList.vue')
  },
  {
    path: 'final-allocated-circular-list',
    name: prop + '.final_allocated_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularFinalAllocatedList.vue')
  },
  {
    path: 'final-allocated-list',
    name: prop + '.final_allocated_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/FinalAllocatedList.vue')
  },
  {
    path: 'nominee-approve-circular-list',
    name: prop + '.nominee_approve_circular_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/CircularNomineeApproveList.vue')
  },
  {
    path: 'nominee-approve-list',
    name: prop + '.nominee_approve_list',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/NomineeApproveList.vue')
  },
  {
    path: 'fertilizer-request-report/:circular_id',
    name: prop + '.fertilizer_request_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/fertilizer-request-process/FertilizerRequestReport.vue')
  },
  // Monthly statistical Bulletin
  {
    path: 'annual-production-target',
    name: prop + '.annual_production_target',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/annual-production-target/List.vue')
  },
  {
    path: 'country-wise-export',
    name: prop + '.country_wise_export',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/country-wise-export/List.vue')
  },
  {
    path: 'country-wise-import',
    name: prop + '.country_wise_import',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/country-wise-import/List.vue')
  },
  {
    path: 'country-wise-import-form',
    name: prop + '.country_wise_import_form',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/country-wise-import/Form.vue')
  },
  {
    path: 'auction-sale-entry',
    name: prop + '.auction_sale_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/auction-sale-entry/List.vue')
  },
  {
    path: 'return-one',
    name: prop + '.return_one',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-one/List.vue')
  },
  {
    path: 'return-one-entry',
    name: prop + '.return_one_entry',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-one/Form.vue')
  },
  // return three routes
  {
    path: 'return-three',
    name: prop + '.return_three',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-three/List.vue')
  },
  {
    path: 'return-form-three',
    name: prop + '.return_form_three',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-three/Form.vue')
  },
  {
    path: 'return-form-three-view/:id',
    name: prop + '.return_form_three_view',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-three/Details.vue')
  },
  {
    path: 'bulletin',
    name: prop + '.bulletin',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/bulletin/List.vue')
  },
  {
    path: 'net-tea-production-report',
    name: prop + '.net_tea_production_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/net-tea-production-report/List.vue')
  },
  {
    path: 'tea-type-wise-net-production-report',
    name: prop + '.tea_type_wise_net_production_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/tea-type-wise-net-production-report/List.vue')
  },
  // return summery report
  // return one
  {
    path: 'return-one-summery-report',
    name: prop + '.return_one_summery_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-one-summery-report/SummeryReport.vue')
  },

  // return one detail
  {
    path: 'return-one-detail-report',
    name: prop + '.return_one_detail_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-one-detail-report/DetailReport.vue')
  },
  // return two
  {
    path: 'return-two-summery-report',
    name: prop + '.return_two_summery_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-two-summery-report/SummeryReport.vue')
  },
  // return three
  {
    path: 'return-three-summery-report',
    name: prop + '.return_three_summery_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-three-summery-report/SummeryReport.vue')
  },
  // return three detail
  {
    path: 'return-three-detail-report',
    name: prop + '.return_three_detail_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/return-three-detail-report/DetailReport.vue')
  },
    // btb license services route load from external panel
    // broker
    {
      path: 'broker-license-application',
      name: prop + '.broker_license_application',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/brokers-application/ListAdmin.vue')
    },
    {
      path: 'broker-license-application-form',
      name: prop + '.broker_license_application_form',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/brokers-application/FormAdmin.vue')
    },
    {
      path: 'broker-license-application-renew-form/:parent_id',
      name: prop + '.broker_license_application_renew_form',
      props: true,
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/brokers-application/RenewFormAdmin.vue')
    },
    {
      path: 'broker-license-application-view/:id',
      name: prop + '.broker_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BrokerDetails/Details.vue')
    },
    {
      path: 'broker-license-application-certificate/:id',
      name: prop + '.broker_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BrokerDetails/Certificate.vue')
    },
    // blender
    {
      path: 'blender-license-application',
      name: prop + '.blender_license_application',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/blender-application/ListAdmin.vue')
    },
    {
      path: 'blender-license-application-form',
      name: prop + '.blender_license_application_form',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/blender-application/FormAdmin.vue')
    },
    {
      path: 'blender-license-application-renew-form/:parent_id',
      name: prop + '.blender_license_application_renew_form',
      props: true,
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/blender-application/RenewFormAdmin.vue')
    },
    {
      path: 'blender-license-application-view/:id',
      name: prop + '.blender_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BlenderDetails/Details.vue')
    },
    {
      path: 'blender-license-application-certificate/:id',
      name: prop + '.blender_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BlenderDetails/Certificate.vue')
    },
    // bidder
    {
      path: 'bidder-license-application',
      name: prop + '.bidder_license_application',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/bidder-application/ListAdmin.vue')
    },
    {
      path: 'bidder-license-application-form',
      name: prop + '.bidder_license_application_form',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/bidder-application/FormAdmin.vue')
    },
    {
      path: 'bidder-license-application-renew-form/:parent_id',
      name: prop + '.bidder_license_application_renew_form',
      props: true,
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/bidder-application/RenewFormAdmin.vue')
    },
    {
      path: 'bidder-license-application-view/:id',
      name: prop + '.bidder_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BidderDetails/Details.vue')
    },
    {
      path: 'bidder-license-application-certificate/:id',
      name: prop + '.bidder_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/BidderDetails/Certificate.vue')
    },
    // retailer
    {
      path: 'retailer-license-application',
      name: prop + '.retailer_license_application',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/retailer-application/ListAdmin.vue')
    },
    {
      path: 'retailer-license-application-form',
      name: prop + '.retailer_license_application_form',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/retailer-application/FormAdmin.vue')
    },
    {
      path: 'retailer-license-application-renew-form/:parent_id',
      name: prop + '.retailer_license_application_renew_form',
      props: true,
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/retailer-application/RenewFormAdmin.vue')
    },
    {
      path: 'retailer-license-application-view/:id',
      name: prop + '.retailer_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/RetailerDetails/Details.vue')
    },
    {
      path: 'retailer-license-application-certificate/:id',
      name: prop + '.retailer_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/RetailerDetails/Certificate.vue')
    },
    // wearhouse
    {
      path: 'wearhouse-license-application',
      name: prop + '.wearhouse_license_application',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/wearhouse-application/ListAdmin.vue')
    },
    {
      path: 'wearhouse-license-application-form',
      name: prop + '.wearhouse_license_application_form',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/wearhouse-application/FormAdmin.vue')
    },
    {
      path: 'wearhouse-license-application-renew-form/:parent_id',
      name: prop + '.wearhouse_license_application_renew_form',
      props: true,
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/wearhouse-application/RenewFormAdmin.vue')
    },
    {
      path: 'wearhouse-license-application-view/:id',
      name: prop + '.wearhouse_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/WearhouseDetails/Details.vue')
    },
    {
      path: 'wearhouse-license-application-certificate/:id',
      name: prop + '.wearhouse_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/WearhouseDetails/Certificate.vue')
    },
     // export license
     {
      path: 'export-license-application',
      name: prop + '.export_license_application',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/export-application/List.vue')
    },
    {
      path: 'export-license-application-form',
      name: prop + '.wearhouse_license_application_form',
      meta: { auth: true },
      component: () => import('../../../external-user-service/views/tea-garden-panel/pages/export-application/Form.vue')
    },
    {
      path: 'export-license-application-view/:id',
      name: prop + '.export_license_application_view',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/ExportDetails/Details.vue')
    },
    {
      path: 'export-license-application-certificate/:id',
      name: prop + '.wearhouse_license_application_certificate',
      meta: { auth: true },
      props: true,
      component: () => import('@/modules/tea-garden-service/btb/ApplicationDetails/License/ExportDetails/Certificate.vue')
    },
    // license report
    {
      path: 'area-wise-license-report',
      name: prop + '.area_wise_license_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/area-wise-license-report/List.vue')
    },
    {
      path: 'financial-summary-report',
      name: prop + '.financial_summary_report',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/financial-summary-report/List.vue')
    },
    // --------------national tea award routes----------
    {
      path: 'national-tea-award-circular',
      name: prop + '.national_tea_award_circular',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/national-tea-award/circular/List.vue')
    },
    {
      path: 'national-tea-award-category',
      name: prop + '.national_tea_award_category',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/national-tea-award/category/List.vue')
    },
    {
      path: 'national-tea-award-application',
      name: prop + '.national_tea_award_application_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/national-tea-award/application/List.vue')
    },
    {
      path: 'national-tea-award-application-approved-list',
      name: prop + '.national_tea_award_application_approved_list',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/national-tea-award/application/ApprovedList.vue')
    },
    {
      path: 'national-tea-award-application/:id',
      name: prop + '.national_tea_award_application_view',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/national-tea-award/application/Details/Details.vue')
    },
    {
      path: 'national-tea-award-application-edit/:id',
      name: prop + '.national_tea_award_application_edit',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/national-tea-award/application/Edit.vue')
    }
]

const routes = [
  {
    path: '/tea-garden-service/btb',
    name: 'tea_garden_service.btb',
    component: () => import('@/layouts/TeaGardenServiceLayout.vue'),
    meta: { auth: true },
    children: btbRoutes('tea_garden_service.btb')
  }
]

export default routes
