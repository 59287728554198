
const concernOfficerManagementRoutes = (prop) => [
  {
    path: 'create-concern-officer',
    name: prop + '.create_concern_officer',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/create-concern-officer/List.vue')
  },
  {
    path: 'concern-officer-profile',
    name: prop + '.concern_officer_profile',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/concern-officer-profile/List.vue')
  },
  {
    path: 'make-schedule',
    name: prop + '.make_schedule',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/make-schedule/List.vue')
  },
  {
    path: 'confirm-appointment',
    name: prop + '.confirm_appointment',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/confirm-appoinment/List.vue')
  }
]

const routes = [
  {
    path: '/trade-tariff-service/concern-officer-management',
    name: 'trade_tariff_service.concern_officer_management',
    component: () => import('@/layouts/TradeTariffServiceLayout.vue'),
    meta: { auth: true },
    children: concernOfficerManagementRoutes('trade_tariff_service.concern_officer_management')
  }
]

export default routes
