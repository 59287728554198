const dashboardRoutes = (prop) => [
  {
      path: 'dashboard',
      name: prop + '.dashboard',
      meta: { auth: true, name: 'Editable' },
      component: () => import('../pages/Dashboard.vue')
  }
]
const configurationRoutes = (prop) => [
  {
    path: 'acid-name',
    name: prop + '.acid_name',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/acid-name/List.vue')
  },
  {
    path: 'payment-info',
    name: prop + '.payment_info',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/payment-info/List.vue')
  },
  {
    path: 'report-heading',
    name: prop + '.report_heading',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/report-heading/List.vue')
  }
]

const routes = [
  {
    path: '/lrcpn-service',
    name: 'lrcpn_service',
    component: () => import('@/layouts/LrcpnServiceLayout.vue'),
    meta: { auth: false },
    children: dashboardRoutes('lrcpn_service')
  },
  {
    path: '/lrcpn-service/configuration',
    name: 'lrcpn_service.configuration',
    component: () => import('@/layouts/LrcpnServiceLayout.vue'),
    meta: { auth: true },
    children: configurationRoutes('lrcpn_service.configuration')
  }
]

export default routes
